import { get, isString } from "lodash";

export const getIfStr = (v: unknown, path: string): string | undefined => {
  const maybeStr: unknown = get(v, path);
  if (isString(maybeStr)) return maybeStr;
};

export function* cycle<T>(array: T[]): Generator<T, never> {
  while (true) for (const v of array) yield v;
}

/**
 * Retorna um novo array com o elemento especificado substituído.
 *
 * Infelizmente o {@link Array.with} não funciona para o nosso caso, pois ele lança erro caso o elemento não exista
 * ainda no array.
 */
export function withElementReplaced<T>(arr: T[], index: number, value: T) {
  if (arr[index] === value) return arr; // não modifica nada se o valor já está na posição correta

  const copy = [...arr]; // caso contrário, cria uma cópia rasa...
  copy[index] = value; // ...e atribui o valor ao índice correto — isso fará o array crescer, se necessário

  return copy;
}
