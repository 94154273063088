import { Suspense } from "react";

import { Paper, Stack } from "@mui/material";

import { Navigate, Outlet, useMatches } from "react-router";

import Aguarde from "../../components/aguarde.tsx";
import ErrorBoundary from "../../components/error-boundary.tsx";
import Titulos from "../../components/titulos.tsx";
import { useAuthContext } from "../../services/auth.ts";
import { getIfStr } from "../../services/util.ts";

export default function AdminLayout() {
  const { currentUser } = useAuthContext();
  const matches = useMatches();
  const [titulo, subtitulo] = matches.map((m) => getIfStr(m.handle, "titulo")).filter(Boolean);
  if (!currentUser?.roles.includes("admin")) return <Navigate to="/" />;

  return (
    <Stack>
      <Titulos titulo={titulo} subtitulo={subtitulo} />
      <Stack
        sx={{
          background: "linear-gradient(#232323 16px, #ffffff 16px, #ffffff 100%)",
          borderRadius: "18px",
        }}
      >
        <Stack component={Paper} flex={1} sx={{ mt: "16px", p: { xs: 0, sm: 4 }, minHeight: "60vh" }}>
          <ErrorBoundary>
            <Suspense fallback={<Aguarde />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </Stack>
      </Stack>
    </Stack>
  );
}
