import { Stack } from "@mui/material";

import { Outlet } from "react-router";

import { useIsTabletOrSmaller } from "../../services/responsividade.ts";
import { MenuDesktopAdmin } from "../shared/menu-desktop-admin.tsx";
import { MenuDesktop } from "../shared/menu-desktop.tsx";

import MainTopBar from "./main-top-bar.tsx";

export default function MainLayout() {
  const isTablet = useIsTabletOrSmaller();

  return (
    <>
      <MainTopBar />

      <Stack
        direction={!isTablet ? "row" : "column"}
        gap={4}
        p={{ xs: 0.5, md: 4 }}
        sx={{ mt: { xs: 7.5, md: 8.5 } }}
        flex={1}
      >
        <Stack gap={2}>
          {!isTablet && <MenuDesktopAdmin />}
          {!isTablet && <MenuDesktop />}
        </Stack>

        <Stack flex={1} gap={1}>
          <Outlet />
        </Stack>
      </Stack>
    </>
  );
}
