import { Stack } from "@mui/material";

import { Outlet } from "react-router";

import ImagemEditavel from "../../components/conteudo-editavel/imagem-editavel";
import BotaoChatBot from "../../routes/publico/chatbot";

import PublicTopBar from "./public-top-bar.tsx";

export default function PublicLayout() {
  return (
    <Stack flex={1}>
      <PublicTopBar />

      <Stack flex={1} gap={4} sx={{ mt: { xs: 8.3, md: 8.3 } }}>
        <ImagemEditavel caminho="area-publica/banner" height={{ xs: "25vh", lg: "35vh" }} horizontal />

        <Stack flex={1} sx={{ width: { xs: "100%", lg: "80%" }, alignSelf: "center", px: { xs: 1 } }}>
          <Outlet />
        </Stack>
      </Stack>
      <Stack
        position="sticky"
        component="footer"
        bottom={30}
        right={0}
        width={60}
        alignSelf="end"
        alignItems="end"
        zIndex={1101}
        justifyContent="end"
        px={4}
      >
        <BotaoChatBot />
      </Stack>
    </Stack>
  );
}
