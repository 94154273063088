import { Card, CardContent, CardHeader, Stack, StackProps } from "@mui/material";

import dayjs from "dayjs";

import { fmtDecimal2Digitos, fmtNumeroInteiro } from "../../../services/formatacao.ts";

import { useSubsidioPassageiroTotalMesAtual, useViagensDia, useViagensMes, useViagensSubsidiadasMes } from "./hooks.ts";

export function Contadores(props: StackProps) {
  const diaRef = dayjs().subtract(1, "week").startOf("day"); // retorna o dia uma semana antes
  const mesRef = dayjs().subtract(1, "month").startOf("day"); // retorna o mês anterior
  const mesAtual = dayjs().startOf("day");

  const subsidioTotalMesAnterior = useSubsidioPassageiroTotalMesAtual(mesAtual);
  const viagensSubsidiadasMesAnterior = useViagensSubsidiadasMes(mesAtual);
  const viagensDia = useViagensDia(diaRef);
  const viagensMes = useViagensMes(mesRef);
  const mesAtualFormatado = mesAtual.format("MMMM[ de ]YYYY");
  const mesRefFormatado = mesRef.format("MMMM[ de ]YYYY");
  const diaSemana = dayjs().format("dddd");
  const noUltimo = diaSemana.endsWith("feira") ? "na última" : "no último";

  const dados = [
    ["Valor de subsídio de passageiros (R$)", subsidioTotalMesAnterior, mesAtualFormatado, fmtDecimal2Digitos],
    [
      "Número de passageiros (transações) subsidiadas",
      viagensSubsidiadasMesAnterior,
      mesAtualFormatado,
      fmtNumeroInteiro,
    ],
    [
      `Viagens de veículos (ida e volta) ${noUltimo} ${diaSemana}`,
      viagensDia,
      diaRef.format("DD/MMM/YYYY"),
      fmtNumeroInteiro,
    ],
    ["Viagens de veículos (ida e volta) acumuladas", viagensMes, mesRefFormatado, fmtNumeroInteiro],
  ] as const;

  return (
    <Stack
      {...props}
      width="100%"
      display="grid"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr 1fr"
      gap={2}
      px={2}
      pb={2}
      sx={{
        ...props.sx,
        "& .MuiCardHeader-root": { bgcolor: "#F7F7F7", color: "#6A6A6A" },
        "& .MuiCardHeader-content span": { fontSize: 12, fontWeight: "bold" },
        "& .MuiCardContent-root:nth-of-type(2)": { py: 2, textAlign: "center", fontSize: 30, color: "primary.main" },
        "& .MuiCardContent-root:nth-of-type(3)": {
          p: 1,
          mt: -3,
          px: 2,
          textAlign: "right",
          fontSize: 8,
          fontWeight: "normal",
          color: "darkgray",
        },
      }}
    >
      {dados.map(([titulo, valor, footer, formater], i) => (
        <Card key={i}>
          <CardHeader title={titulo} sx={{ textAlign: "center" }} />
          <CardContent>{formater.format(valor)}</CardContent>
          {footer && <CardContent>{footer}</CardContent>}
        </Card>
      ))}
    </Stack>
  );
}
