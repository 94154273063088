import { lazy, Suspense } from "react";

const MapaCalor = lazy(() => import("./mapa-calor.tsx"));

export default function MapaDeCalor() {
  return (
    <Suspense fallback={null}>
      <MapaCalor />
    </Suspense>
  );
}
