import { CompareArrows as CompareArrowsIcon } from "@mui/icons-material";

import { Navigate } from "react-router";

import { IconeSubsidios } from "../../assets/icones";
import DashboardLayout from "../../layouts/dashboard-layout";
import { MobnitRouteObject } from "../types.ts";

import Comparativo from "./comparativo";
import Subsidios from "./subsidios";

export const routes: MobnitRouteObject = {
  path: "auditoria",
  handle: { titulo: "Auditoria" },
  element: <DashboardLayout />,
  children: [
    { index: true, element: <Navigate to="subsidios" replace /> },
    {
      path: "subsidios",
      element: <Subsidios />,
      handle: { titulo: "Subsídios", icone: <IconeSubsidios /> },
    },
    {
      path: "comparativo",
      element: <Comparativo />,
      handle: { titulo: "Comparativo", icone: <CompareArrowsIcon /> },
    },
  ],
};
