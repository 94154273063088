import { lazy, Suspense } from "react";

import { Card, CardContent, Stack } from "@mui/material";

import Titulos from "../../../components/titulos.tsx";

const MapaVM = lazy(() => import("./mapa-vm.tsx"));

export default function Videomonitoramento() {
  const mapHeight = "75vh";
  const videoBaseWidth = "40vw";

  return (
    <Stack>
      <Titulos titulo="Mapas" subtitulo="Videomonitoramento" />
      <Card>
        <CardContent
          sx={{
            p: 2,
            ["& > div"]: { height: mapHeight },
            ["& .leaflet-popup-content-wrapper"]: { width: `calc(${videoBaseWidth} + 40px)` },
          }}
        >
          <Suspense fallback={null}>
            <MapaVM videoBaseWidth={videoBaseWidth} />
          </Suspense>
        </CardContent>
      </Card>
    </Stack>
  );
}
