import { useEffect, useState } from "react";

import { hasAuthParams, useAuth, withAuthenticationRequired } from "react-oidc-context";
import { Navigate, Outlet, useLocation } from "react-router";

import { useAuthContext } from "../../services/auth.ts";
import Aguarde from "../aguarde.tsx";

export default function RequireAuth() {
  const { currentUser } = useAuthContext();
  const location = useLocation();

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (hasTriedSignin || auth.isAuthenticated || auth.activeNavigator || auth.isLoading || hasAuthParams()) return;

    auth.signinRedirect().catch(reportError);
    setHasTriedSignin(true);
  }, [auth, hasTriedSignin]);

  if (currentUser === undefined) return <Aguarde />;
  if (currentUser === null) return <Navigate to="/" state={{ from: location }} replace />;

  return <OutletRequireAuth />;
}

const OutletRequireAuth = withAuthenticationRequired(Outlet, {
  OnRedirecting() {
    return <Aguarde>Aguarde, redirecionando à página de login</Aguarde>;
  },
});
