import { useMemo, useState } from "react";

import { Search } from "@mui/icons-material";
import { Chip, InputAdornment, lighten, Stack, StackProps, TextField } from "@mui/material";

import { useSearchParams } from "react-router";

import { useRegexParaBuscaSimples } from "../../services/buscas.ts";
import Aguarde from "../aguarde.tsx";
import LinhaTabela from "../tabela/linha-tabela.tsx";
import Tabela from "../tabela/tabela.tsx";
import { InfoMinimaColunas } from "../tabela/types.ts";

import BotaoVerItinerario from "./botao-ver-itinerario.tsx";
import { useLinhas } from "./hooks.ts";
import { DadosLinha } from "./tipos.ts";

export default function ListaLinhas(props: StackProps) {
  const { registros } = useLinhas();

  const [params] = useSearchParams();

  const valorSelecionado = params.get("linha");

  const [filtro, setFiltro] = useState("");
  const fnFiltro = useRegexParaBuscaSimples(filtro);

  const detalhado = useMemo(
    (): DadosLinha[] => [
      { numeroLinha: "", nomeLinha: "Todas", ativa: !valorSelecionado },
      ...(registros
        ?.filter((r) => !fnFiltro || fnFiltro(r.numeroLinha) || fnFiltro(r.nomeLinha))
        ?.map((r) => ({ ...r, ativa: r.numeroLinha === valorSelecionado })) ?? []),
    ],
    [registros, fnFiltro, valorSelecionado],
  );

  return (
    <Stack gap={2} {...props}>
      <TextField
        type="search"
        value={filtro}
        onChange={(e) => setFiltro(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search color="disabled" />
            </InputAdornment>
          ),
        }}
        size="small"
        label="Busca"
        disabled={!detalhado}
      />

      {!detalhado ? (
        <Aguarde />
      ) : (
        <Tabela
          size="small"
          linhas={detalhado}
          colunas={colunas}
          sx={{
            "& tbody td:first-of-type": { pl: 1, pr: 0 },
            "& tbody td:last-of-type": { px: 1 },
            "& tbody tr:first-of-type td": { fontWeight: "bold" },
            "& tr.Mui-selected": { backgroundColor: "primary.main" },
            "& tr.Mui-selected:hover": { backgroundColor: (t) => lighten(t.palette.primary.main, 0.2) },
            "& tr.Mui-selected td": { color: "primary.contrastText" },
            "& tr.Mui-selected .MuiButton-root": { color: "primary.contrastText" },
            "& tr.Mui-selected .MuiChip-root": { bgcolor: "primary.contrastText" },
          }}
          componentes={{
            // eslint-disable-next-line react/prop-types
            TableRow: (props) => <LinhaTabela {...props} selected={detalhado?.[props["data-index"]]?.ativa} />,
          }}
        />
      )}
    </Stack>
  );
}

const colunas: InfoMinimaColunas<DadosLinha>[] = [
  {
    id: "numeroLinha",
    label: "",
    type: "custom",
    width: "3.5rem",
    renderer: (row) => row.numeroLinha && <Chip label={row.numeroLinha} size="small" sx={{ fontSize: 10 }} />,
  },
  {
    id: "nomeLinha",
    label: "Linha",
    type: "custom",
    width: "*",
    renderer: (row) => <small>{row.nomeLinha}</small>,
  },
  {
    id: "visualizar",
    label: "Rota",
    type: "custom",
    width: "5.5em",
    renderer: (row) => <BotaoVerItinerario valor={row.numeroLinha} />,
  },
];
