import { Outlet } from "react-router";

import { IconeFrota } from "../../assets/icones";
import PageLayout from "../../layouts/page-layout.tsx";
import { MobnitRouteObject } from "../types.ts";

import RedirecionaVistoria from "./redireciona-vistoria.tsx";

export const routes: MobnitRouteObject = {
  path: "formulario",
  handle: { titulo: "Formulário" },
  element: (
    <PageLayout>
      <Outlet />
    </PageLayout>
  ),
  children: [
    {
      path: "vistoria",
      element: <RedirecionaVistoria />,
      handle: { titulo: "Cadastro de Veículos", icone: <IconeFrota /> },
    },
  ],
};
