import { ChangeEvent, ReactNode, useCallback } from "react";

import { Box, Checkbox, Divider, FormControlLabel, Stack, Typography } from "@mui/material";

import { useFormContext } from "react-hook-form";
import Markdown from "react-markdown";

import { useSWRFromAPI } from "../../../../services/api/swr.ts";
import { Inputs } from "../tipos.ts";

export default function Dados({ readonly = false }: { readonly?: boolean }) {
  const { data: grupos } = useSWRFromAPI("/cadastros/apis/opcoes");

  const { watch, setValue } = useFormContext<Inputs>();

  const dados = watch("dados");

  const handleToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>, marcada: boolean) => {
      const dado = e.target.value;
      const naLista = dados.includes(dado);

      if (marcada && !naLista) setValue("dados", [...dados, dado]);
      else if (!marcada && naLista)
        setValue(
          "dados",
          dados.filter((d) => d !== dado),
        );
    },
    [dados, setValue],
  );

  return (
    <Stack flex={1} sx={{ color: "rgba(131, 135, 154, 1)", ["& .MuiFormControlLabel-label"]: { fontSize: "0.75em" } }}>
      <Typography>Dados</Typography>
      <Divider />
      <Stack direction="row" flexWrap="wrap" columnGap={2} gap={2} mt={3}>
        {grupos.map(({ codigo: codigoGrupo, paginas, titulo }) => (
          <Box key={titulo} mt={1}>
            <Bloco key={codigoGrupo} titulo={titulo ?? ""}>
              {paginas?.map(({ dados: itens, titulo: tituloPagina, codigo: codigoPagina }) => (
                <Box key={tituloPagina}>
                  <Box fontWeight={700} fontSize={12} pt={1.25}>
                    {tituloPagina}
                  </Box>
                  {itens.map(({ titulo: tituloDados, codigo: codigoDados }) => {
                    if (!tituloDados) return null;

                    const value = `${codigoGrupo}/${codigoPagina}/${codigoDados}`;

                    return (
                      <Box key={value}>
                        <FormControlLabel
                          label={<Markdown>{tituloDados ?? ""}</Markdown>}
                          sx={{ my: "-10px" }}
                          control={
                            <Checkbox
                              size="small"
                              onChange={handleToggle}
                              value={value}
                              checked={dados.includes(value)}
                              disabled={readonly}
                            />
                          }
                        />
                      </Box>
                    );
                  })}
                </Box>
              ))}
            </Bloco>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

const Bloco = ({ titulo, children }: { titulo: string; children: ReactNode }) => (
  <Box>
    <Typography fontSize={12} fontWeight={700} sx={{ color: "primary.main" }}>
      {titulo}
    </Typography>
    <Stack pl={2} gap={{ xs: 2, md: 0 }}>
      {children}
    </Stack>
  </Box>
);
