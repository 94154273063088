import { MouseEvent, useCallback, useMemo } from "react";

import { Stack, StackProps, SvgIcon, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useSearchParams } from "react-router";

import iconeTrasnit from "./icones/onibus-transnit.svg";
import iconeTrasnoceanico from "./icones/onibus-transoceanico.svg";
import iconeParada from "./icones/parada.svg";

const TODOS = ["ida", "volta", "paradas", "onibus", "transnit", "transoceanico"];
const PARAM_NAME = "x";

export default function LegendaItinerario(props: StackProps) {
  const [params, setParams] = useSearchParams();

  const value = useMemo(() => TODOS.filter((v) => !params.getAll(PARAM_NAME).includes(v)), [params]);

  const handleChange = useCallback(
    (_e: MouseEvent, value: string[]) => {
      setParams((p) => {
        p.delete(PARAM_NAME);
        TODOS.forEach((v) => {
          if (!value.includes(v)) p.append(PARAM_NAME, v);
        });

        return p;
      });
    },
    [setParams],
  );

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={1}
      py={0.5}
      {...props}
      sx={{ ...props.sx, ".MuiButtonBase-root": { gap: 0.5 } }}
    >
      <StyledToggleButtonGroup value={value} onChange={handleChange}>
        <ToggleButton size="small" value="ida">
          <SvgIcon>
            <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
              <circle r={3.5} cx={5} cy={5} color="#F5792B" fill="#F5792B" />
            </svg>
          </SvgIcon>
          ida
        </ToggleButton>

        <ToggleButton size="small" value="volta">
          <SvgIcon>
            <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
              <circle r={3.5} cx={5} cy={5} color="#F5792B" fill="#59C3C8" />
            </svg>
          </SvgIcon>
          volta
        </ToggleButton>

        <ToggleButton size="small" value="paradas">
          <img src={iconeParada} alt="Paradas" />
          parada
        </ToggleButton>

        <ToggleButton size="small" value="transnit">
          <img src={iconeTrasnit} alt="" />
          TransNit
        </ToggleButton>
        <ToggleButton size="small" value="transoceanico">
          <img src={iconeTrasnoceanico} alt="" />
          Transoceânico
        </ToggleButton>
      </StyledToggleButtonGroup>
    </Stack>
  );
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: "1px solid transparent",
  },
}));
