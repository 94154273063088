import { useCallback } from "react";

import { Provider as AtomProvider } from "jotai";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

import { typedRequest } from "../../../../services/api";
import { useSWRFromAPI } from "../../../../services/api/swr.ts";
import { useAuthAxios } from "../../../../services/fetchers.ts";
import { Inputs } from "../formulario/tipos.ts";

import Formulario from "./formulario.tsx";

export default function EdicaoUsuario() {
  const { id = "" } = useParams();
  const { data: usuario, mutate } = useSWRFromAPI("/cadastros/usuario/{id}", { id }, null, { forEditing: true });
  const authAxios = useAuthAxios();

  const form = useForm<Inputs>({
    defaultValues: {
      ...usuario,
      nome: usuario?.nome ?? "",
      sobrenome: usuario?.sobrenome ?? "",
      perfil: usuario?.perfil ?? undefined,
      permissoes: new Set<string>(usuario?.permissoes ?? []),
    },
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const submit = useCallback(
    async (dados: Inputs) => {
      // chamar a API com os dados
      await typedRequest(
        authAxios,
        "put",
        "/cadastros/usuario/{id}",
        { id },
        {
          data: { ...dados, permissoes: [...dados.permissoes] },
          params: { redirect_uri: `${location.origin}/gestor`, client_id: import.meta.env.VITE_OAUTH_CLIENT_ID },
        },
      );

      enqueueSnackbar({ variant: "success", message: "Usuário atualizado com sucesso" });
      await mutate();
      await navigate("..");
    },
    [authAxios, enqueueSnackbar, id, mutate, navigate],
  );

  if (!usuario) return null;

  return (
    <FormProvider {...form}>
      <AtomProvider>
        <Formulario submit={submit} usuario={usuario} />
      </AtomProvider>
    </FormProvider>
  );
}
