import { FormEvent, useCallback, useMemo, useRef } from "react";

import { OutlinedInput } from "@mui/material";

import { debounce } from "lodash";
import { useSearchParams } from "react-router";

import buscaIcon from "../../../../assets/icones/busca.svg";

export function Busca() {
  const refInput = useRef<HTMLInputElement>();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearch = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      const q = refInput.current?.value;
      setSearchParams((sp) => {
        if (q) sp.set("q", q);
        else sp.delete("q");

        return sp;
      });
    },
    [setSearchParams],
  );

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 500), [handleSearch]);

  return (
    <form method="get">
      <OutlinedInput
        defaultValue={searchParams.get("q") ?? ""}
        inputRef={refInput}
        endAdornment={<img src={buscaIcon} alt="" />}
        sx={{ height: "2.3em" }}
        placeholder="Busca"
        type="search"
        onChange={debouncedHandleSearch}
      />
    </form>
  );
}
