import { Navigate } from "react-router";

import { IconeFrota, IconePassageiro, IconeQuilometragem, IconeViagens } from "../../assets/icones";
import DashboardLayout from "../../layouts/dashboard-layout";
import { MobnitRouteObject } from "../types.ts";

import Frota from "./frota";
import Passageiros from "./passageiros";
import Quilometragem from "./quilometragem";
import Viagens from "./viagens";

export const routes: MobnitRouteObject = {
  path: "dados-operacionais",
  handle: { titulo: "Dados Operacionais" },
  element: <DashboardLayout />,
  children: [
    { index: true, element: <Navigate to="passageiros" replace /> },
    {
      path: "passageiros",
      element: <Passageiros />,
      handle: { titulo: "Pessoas (Estimativa)", icone: <IconePassageiro /> },
    },
    {
      path: "frota",
      element: <Frota />,
      handle: { titulo: "Frota", icone: <IconeFrota /> },
    },
    {
      path: "quilometragem",
      element: <Quilometragem />,
      handle: { titulo: "Quilometragem", icone: <IconeQuilometragem /> },
    },
    {
      path: "viagens",
      element: <Viagens />,
      handle: { titulo: "Passageiros (Transações)", icone: <IconeViagens /> },
    },
  ],
};
