import { Button, ButtonProps } from "@mui/material";

import { Link, To } from "react-router";

export default function BotaoLink({ children, to, sx, ...props }: { to?: To } & ButtonProps) {
  const linkProps = to ? { component: Link, to } : {};

  return (
    <Button {...props} {...linkProps} sx={{ ...sx }}>
      {children}
    </Button>
  );
}
