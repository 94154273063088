import { useMemo } from "react";

import { Stack, Typography } from "@mui/material";

import { useSearchParams } from "react-router";

import BotaoDownload from "../../../../components/botao-download";
import NaoInformado from "../../../../components/frames/nao-informado.tsx";
import { Tabela } from "../../../../components/tabela";
import { useSWRFromAPI } from "../../../../services/api/swr.ts";
import AcaoAprovar from "../acoes/acao-aprovar.tsx";
import AcaoRejeitar from "../acoes/acao-rejeitar.tsx";
import { Busca } from "../acoes/busca.tsx";

export default function Autorizacoes() {
  const [searchParams] = useSearchParams();
  const { data: todosRegistros, mutate } = useSWRFromAPI("/cadastros/autorizacoes");

  const q = searchParams.get("q") ?? "";
  const registros = useMemo(
    () => todosRegistros.filter((r) => [r.nome, r.email].some((v) => v?.toLowerCase().includes(q))),
    [q, todosRegistros],
  );

  return (
    <Stack flex={1}>
      <Stack flexDirection="row" alignItems="center" gap={1} mb={2}>
        <Typography display="flex" flex={1} fontSize={20}>
          Solicitações de cadastro no MobNit
        </Typography>

        <Busca />
        <BotaoDownload registros={registros} />
      </Stack>

      <Stack height="50vh">
        <Tabela
          colunas={[
            {
              id: "nome",
              label: "Nome",
              type: "custom",
              renderer: (row) => `${row.nome} ${row.sobrenome}`,
            },
            {
              id: "instituicao",
              label: "Instituição",
              type: "custom",
              renderer: (row) => row.instituicao || <NaoInformado>(não informada)</NaoInformado>,
            },
            {
              id: "cargo",
              label: "Cargo",
              type: "custom",
              renderer: (row) => row.cargo || <NaoInformado />,
            },
            { id: "email", label: "Email", type: "string" },
            {
              id: "rejeitar",
              label: "",
              type: "custom",
              sortable: false,
              width: "9em",
              renderer: (row) => <AcaoRejeitar usuario={row} icone onSuccess={mutate} />,
            },
            {
              id: "aprovar",
              label: "",
              width: "9em",
              type: "custom",
              sortable: false,
              renderer: (row) => <AcaoAprovar usuario={row} onSuccess={mutate} />,
            },
          ]}
          linhas={registros}
        />
      </Stack>
    </Stack>
  );
}
