import { Box, Stack } from "@mui/material";

import FrotaDisponivelProgramada from "./frota-disp-prog.tsx";
import Ici from "./ici.tsx";
import Icv from "./icv.tsx";
import Idf from "./idf.tsx";
import VariacaoFrota from "./variacao-frota.tsx";

export default function IndicadoresFrota() {
  return (
    <Stack gap={2}>
      <Stack
        sx={{ display: { xs: "flex", md: "grid" }, p: { xs: 1, md: 0 } }}
        gridTemplateColumns="repeat(3, 1fr)"
        gridAutoRows="9.5rem"
        gap={2}
      >
        <Idf />
       <Box display="none"> <Ici /></Box>
        <Icv />
      </Stack>
      <FrotaDisponivelProgramada />
      <VariacaoFrota />
    </Stack>
  );
}
