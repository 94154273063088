import { Button, Stack } from "@mui/material";

import { useFormContext } from "react-hook-form";
import { Link } from "react-router";

import AreaFormulario from "../formulario/area-formulario.tsx";
import Bloco from "../formulario/bloco.tsx";
import Estrategico from "../formulario/estrategico.tsx";
import PerfilSelecao from "../formulario/perfil-selecao.tsx";
import { Inputs } from "../formulario/tipos.ts";

interface Props {
  submit: (dados: Inputs) => Promise<void>;
}

export default function Formulario({ submit }: Props) {
  const { handleSubmit, register } = useFormContext<Inputs>();

  return (
    <AreaFormulario onSubmit={handleSubmit(submit)} titulo="Novo usuário">
      <Bloco titulo="Email" {...register("email")} required />

      <PerfilSelecao {...register("perfil")} required />

      <Estrategico />

      <Stack display="grid" gridTemplateColumns="repeat(2, 9.5em)" flex={1} justifyContent="center" gap={1}>
        <Button variant="contained" color="secondary" component={Link} to="..">
          Cancelar
        </Button>
        <Button variant="contained" color="primary" type="submit" sx={{ whiteSpace: "nowrap" }}>
          Criar novo usuário
        </Button>
      </Stack>
    </AreaFormulario>
  );
}
