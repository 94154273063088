import { Button, Stack } from "@mui/material";

import { useFormContext } from "react-hook-form";
import { Link } from "react-router";

import { API } from "../../../../services/api";
import { fmtMesPorExtenso } from "../../../../services/formatacao.ts";
import AcaoHabilitarDesabilitar from "../acoes/acao-habilitar-desabilitar.tsx";
import AreaFormulario from "../formulario/area-formulario.tsx";
import Bloco from "../formulario/bloco.tsx";
import Estrategico from "../formulario/estrategico.tsx";
import PerfilSelecao from "../formulario/perfil-selecao.tsx";
import { Inputs } from "../formulario/tipos.ts";

interface Props {
  submit: (dados: Inputs) => void;
  usuario: API["UsuarioCadastrado"];
}

export default function Formulario({ usuario, submit }: Props) {
  const { handleSubmit, register } = useFormContext<Inputs>();

  return (
    <AreaFormulario
      onSubmit={handleSubmit(submit)}
      titulo={
        <>
          {usuario.nome} {usuario.sobrenome}
          <span>Com acesso desde {fmtMesPorExtenso.format(new Date(usuario.dataRegistro))}</span>
        </>
      }
    >
      <Bloco {...register("nome")} titulo="Nome" autoFocus />
      <Bloco {...register("sobrenome")} titulo="Sobrenome" />
      <Bloco {...register("email")} titulo="Email" />
      <Bloco {...register("instituicao")} titulo="Instituição" />
      <Bloco {...register("cargo")} titulo="Cargo" />

      <PerfilSelecao {...register("perfil")} />

      <Estrategico />

      <Stack display="grid" gridTemplateColumns="repeat(2, 9.5em)" flex={1} justifyContent="center" gap={1}>
        <Button variant="contained" color="secondary" component={Link} to="..">
          Cancelar
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Salvar
        </Button>
      </Stack>

      <AcaoHabilitarDesabilitar usuario={usuario} />
    </AreaFormulario>
  );
}
