export const fmtNumeroInteiro = new Intl.NumberFormat("pt-BR", { maximumFractionDigits: 0 });

export const fmtDecimal = new Intl.NumberFormat("pt-BR", { maximumFractionDigits: 2 });

export const fmtDecimal2Digitos = new Intl.NumberFormat("pt-BR", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const fmtPercentual = new Intl.NumberFormat("pt-BR", { style: "percent", maximumFractionDigits: 2 });

export const fmtIndicador = new Intl.NumberFormat("pt-BR", {
  maximumFractionDigits: 2,
  notation: "compact",
});

export const fmtIndicadorMuitoBaixo = new Intl.NumberFormat("pt-BR", { maximumFractionDigits: 5 });

export const fmtIndicadorKm = new Intl.NumberFormat("pt-BR", {
  maximumFractionDigits: 2,
  style: "unit",
  unit: "kilometer",
});

export const fmtMoedaBR = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  maximumFractionDigits: 2,
});

export const fmtShortTime = new Intl.DateTimeFormat("pt-BR", { dateStyle: "short", timeStyle: "short" });

export const fmtDataCurtaUTC = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC", dateStyle: "short" });

export const fmtDataCurtaBrasilia = new Intl.DateTimeFormat("pt-BR", { dateStyle: "short" });

export const fmtDiaMesBrasilia = new Intl.DateTimeFormat("pt-BR", {
  day: "numeric",
  month: "numeric",
});

export const fmtDataSimples = new Intl.DateTimeFormat("pt-BR");

export const fmtHoraSimples = new Intl.DateTimeFormat("pt-BR", { hour: "2-digit", minute: "2-digit" });

export const fmtMesPorExtenso = new Intl.DateTimeFormat("pt-BR", { dateStyle: "long" });

export const fmtDataHoraCurta = new Intl.DateTimeFormat("pt-BR", {
  dateStyle: "short",
  timeStyle: "short",
});
