import { Box, Card, CardMedia } from "@mui/material";

import { Outlet } from "react-router";

import logoMobNit from "../assets/logo-mobnit.png";

export default function AuthLayout() {
  return (
    <Box display="flex" minHeight="100vh" alignItems="center" justifyContent="center" flex="1">
      <Card sx={{ padding: "15px" }}>
        <Box padding={1}>
          <CardMedia component="img" height={100} image={logoMobNit} />
        </Box>

        <Outlet />
      </Card>
    </Box>
  );
}
