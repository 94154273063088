import { useEffect, useState } from "react";

import { Navigate, useNavigate } from "react-router";

import { useAuthContext } from "../services/auth.ts";

export default function Login() {
  const { signInIntegrated, currentUser } = useAuthContext();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser || hasTriedSignin) return;

    signInIntegrated().catch(reportError);
    setHasTriedSignin(true);
  }, [currentUser, hasTriedSignin, navigate, signInIntegrated]);

  return <Navigate to="/gestor" />;
}
