import { MobnitRouteObject } from "../types.ts";

import BoasVindas from "./boas-vindas";
import HomeUsuario from "./home";
import Itinerarios from "./itinerarios";
import Sobre from "./sobre";

export const routes: MobnitRouteObject[] = [
  {
    index: true,
    element: <HomeUsuario />,
    handle: {
      titulo: "Home",
      palavrasChave: "ano home inicial mês passageiros primeira principal página viagens última último",
    },
  },
  {
    path: "boas-vindas",
    element: <BoasVindas />,
    handle: { titulo: "Boas vindas" },
  },
  {
    path: "sobre",
    element: <Sobre />,
    handle: {
      titulo: "Sobre",
      palavrasChave:
        "acompanhamento assertivo bojo chegar cidade confiabilidade confiança consulta dados decisão digitalização " +
        "diário falta futuro gestor informações mensal mobilidade mobnit monitoramento município niterói operação " +
        "ponto prefeitura previsibilidade processo produzir público real setor sistema sociedade tempo todo " +
        "transporte urbana ônibus",
    },
  },
  /* {
    path: "horarios",
    element: <HorariosPontualidade />,
    handle: {
      titulo: "Horários e pontualidade",
      palavrasChave:
        "antonio atrasado atraso consórcio dia empresa hora horário iniciada linha nome não pendotiba " +
        "pontualidade santo status transnit transoceanico viagem",
      incluiLinhasNasPalavrasChave: true,
    },
  }, */
  {
    path: "itinerarios",
    element: <Itinerarios />,
    handle: {
      titulo: "Itinerários, rotas e tarifas",
      palavrasChave:
        "apresentação até baldeação bilhete cartão circular deficiência estudante gratuidade gratuito graça " +
        "hora ida idoso integração intermunicipal intervalo itinerários limitação linha mapa mobilidade municipal " +
        "normal parada pessoas possuem renda respectivo rotas serviço tarifa tarifas tem transnit " +
        "transoceanica volta ônibus único",
      incluiLinhasNasPalavrasChave: true,
    },
  },
];
