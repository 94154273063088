import { lazy, Suspense } from "react";

import ErrorBoundary from "../error-boundary.tsx";
import { ObjetoDados } from "../tabela/types.ts";

import { Props } from "./types.ts";

const Botao = lazy(() => import("./botao-download.tsx"));

export default function BotaoDownload<T extends ObjetoDados>(props: Props<T>) {
  return (
    <ErrorBoundary>
      <Suspense>
        <Botao {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
