import { Route as RouteIcon } from "@mui/icons-material";

import { Navigate } from "react-router";

import MapasDeCalorIcon from "../../assets/icones/mapas-de-calor-icon.tsx";
import MobilidadeIcon from "../../assets/icones/mobilidade.tsx";
import VideoCameraIcon from "../../assets/icones/video-camera.tsx";
import DashboardLayout from "../../layouts/dashboard-layout";
import { MobnitRouteObject } from "../types.ts";

import Itinerarios from "./itinerarios";
import MapaDeCalor from "./mapa-de-calor";
import Mobilidade from "./mobilidade";
import Videomonitoramento from "./videomonitoramento";

export const routes: MobnitRouteObject = {
  path: "mapas",
  handle: { titulo: "Mapas" },
  children: [
    { index: true, element: <Navigate to="videomonitoramento" replace /> },
    {
      path: "videomonitoramento",
      element: <Videomonitoramento />,
      handle: { titulo: "Videomonitoramento", icone: <VideoCameraIcon /> },
    },
    {
      path: "itinerarios",
      element: <Itinerarios />,
      handle: { titulo: "Itinerários", icone: <RouteIcon /> },
    },
    {
      path: "mobilidade",
      element: <Mobilidade />,
      handle: { titulo: "Mobilidade", icone: <MobilidadeIcon /> },
    },
    {
      element: <DashboardLayout />,
      children: [
        {
          path: "mapas-de-calor",
          element: <MapaDeCalor />,
          handle: { titulo: "Mapa de Calor", icone: <MapasDeCalorIcon /> },
        },
      ],
    },
  ],
};
