import { Navigate } from "react-router";

import GestaoAPIIcon from "../../assets/icones/gestao-api.tsx";
import GestaoUsuariosIcon from "../../assets/icones/gestao-usuarios.tsx";
import { MobnitRouteObject } from "../types.ts";

import Api from "./gestao-apis";
import EditarEndpoint from "./gestao-apis/acoes/editar-endpoint.tsx";
import Novo from "./gestao-apis/acoes/novo.tsx";
import Detalhes from "./gestao-apis/detalhes.tsx";
import ListaEndpoints from "./gestao-apis/lista.tsx";
import Autorizacoes from "./gestao-usuarios/autorizacoes";
import EdicaoUsuario from "./gestao-usuarios/edicao";
import HistoricoUsuario from "./gestao-usuarios/historico";
import GestaoUsuariosLayout from "./gestao-usuarios/layout.tsx";
import ListaUsuarios from "./gestao-usuarios/lista";
import NovoUsuario from "./gestao-usuarios/novo";
import AdminLayout from "./layout.tsx";

export const routes: MobnitRouteObject = {
  path: "admin",
  handle: { titulo: "ADMIN" },
  element: <AdminLayout />,
  children: [
    { index: true, element: <Navigate to="usuarios" replace /> },
    {
      path: "usuarios",
      handle: {
        icone: <GestaoUsuariosIcon />,
        titulo: "Gestão de usuários",
      },
      children: [
        {
          element: <GestaoUsuariosLayout />,
          handle: {
            icone: <GestaoUsuariosIcon />,
            titulo: "",
          },
          children: [
            { index: true, element: <ListaUsuarios /> },
            { path: "autorizacoes", element: <Autorizacoes /> },
            { path: ":id/editar", element: <EdicaoUsuario /> },
            { path: ":id/historico", element: <HistoricoUsuario /> },
            { path: "novo", element: <NovoUsuario /> },
          ],
        },
      ],
    },

    {
      path: "apis",
      element: <Api />,
      handle: {
        titulo: "Gestão de APIs",
        icone: <GestaoAPIIcon />,
      },
      children: [
        { index: true, element: <ListaEndpoints /> },
        { path: "novo", element: <Novo /> },
        { path: ":id/detalhes", element: <Detalhes /> },
        { path: ":id/editar", element: <EditarEndpoint /> },
      ],
    },
  ],
};
