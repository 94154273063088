import { useCallback, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";

import fecharIcon from "../../../../assets/icones/close.svg";
import aprovarIcon from "../../../../assets/icones/green-checked.svg";
import BotaoLink from "../../../../components/botao-link.tsx";
import { API, typedRequest } from "../../../../services/api";
import { useAuthAxios } from "../../../../services/fetchers.ts";
import Estrategico from "../formulario/estrategico.tsx";
import PerfilSelecao from "../formulario/perfil-selecao.tsx";
import { Inputs } from "../formulario/tipos.ts";

interface Props {
  usuario: API["UsuarioCadastrado"];
  onSuccess?: () => void;
}

export default function AcaoAprovar({ usuario, onSuccess }: Props) {
  const form = useForm<Inputs>({ defaultValues: { permissoes: new Set<string>() } });
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);

  const { enqueueSnackbar } = useSnackbar();

  const authAxios = useAuthAxios();
  const submit = useCallback(
    async (inputs: Inputs) => {
      if (!usuario.id) return;

      try {
        const data = { ...inputs, permissoes: [...inputs.permissoes] };

        await typedRequest(authAxios, "put", "/cadastros/usuario/{id}/aprovar", { id: usuario.id }, { data });

        enqueueSnackbar({ variant: "success", message: "Usuário aprovado!" });
        if (onSuccess) onSuccess();

        setOpen(false);
      } catch (e) {
        reportError(e);
        enqueueSnackbar({
          variant: "error",
          message: (
            <>
              Ocorreu um erro imprevisto ao tentar aprovar o usuário.
              <br />
              Tente novamente mais tarde, ou entre em contato com o suporte.
            </>
          ),
        });
      }
    },
    [authAxios, enqueueSnackbar, onSuccess, usuario.id],
  );

  return (
    <FormProvider {...form}>
      <BotaoLink
        onClick={toggleOpen}
        startIcon={<img src={aprovarIcon} alt="" style={{ zoom: 0.85 }} />}
        sx={{ color: "rgba(39, 174, 96, 1)", textDecoration: "underline", textWrap: "nowrap" }}
      >
        Aprovar
      </BotaoLink>

      <Dialog open={isOpen} onClose={toggleOpen} maxWidth="xs" sx={{ "& p": { color: "#83879A" } }}>
        <Stack component="form" onSubmit={form.handleSubmit(submit)}>
          <IconButton sx={{ m: "0.3em 0.3em   0 auto", bgcolor: "#D9D9D9" }} onClick={toggleOpen}>
            <img src={fecharIcon} alt="Fechar" />
          </IconButton>

          <DialogTitle color="rgba(39, 174, 96, 1)">Aprovar usuário</DialogTitle>

          <DialogContent>
            <Typography sx={{ "& span": { fontWeight: 700 }, "mb": 2.5 }}>
              Selecione o perfil do usuário{" "}
              <span>
                {usuario.nome} {usuario.sobrenome}
              </span>{" "}
              (<span>{usuario.email}</span>)?
            </Typography>

            <Typography mb={3}>Esta ação ficará registrada no histórico do sistema.</Typography>

            <PerfilSelecao {...form.register("perfil")} sx={{ mb: 4 }} />
            <Estrategico />
          </DialogContent>

          <DialogActions sx={{ pb: 2.5, alignSelf: "center" }}>
            <Button variant="contained" sx={{ bgcolor: "#AEAFB4" }} onClick={toggleOpen}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit" sx={{ bgcolor: "rgba(39, 174, 96, 1)" }}>
              Aprovar usuário
            </Button>
          </DialogActions>
        </Stack>
      </Dialog>
    </FormProvider>
  );
}
