import { Card, Stack } from "@mui/material";

import { Link } from "react-router";

import iconeItinerarios from "../../../assets/icones/itinerarios.svg";
import BotaoLink from "../../../components/botao-link.tsx";
import ImagemEditavel from "../../../components/conteudo-editavel/imagem-editavel";

import Busca from "./busca";
import { Contadores } from "./contadores.tsx";

export default function HomeUsuario() {
  return (
    <Stack alignItems="center" gap={4} flex={1}>
      <Stack sx={{ width: { xs: "90vw", lg: "1000px" } }}>
        <Busca />
      </Stack>

      <Card sx={{ width: { xs: "90vw", lg: "1000px" } }}>
        <Stack flexDirection="row">
          <Stack flex={1} gap={3} alignItems="center" pt={4.5}>
            <Stack flexDirection="row" gap={1.5} pb={1}>
              <BotaoLink sx={{}}>
                <Stack
                  component={Link}
                  to="/itinerarios"
                  sx={{
                    textDecoration: "none",
                    fontWeight: 700,
                    alignItems: "center",
                    color: "white",
                    fontSize: "1.125rem",
                    backgroundColor: "#683AB7",
                    p: 3,
                    borderRadius: "8px",
                  }}
                >
                  <img src={iconeItinerarios} alt="" />
                  Itinerários e Rotas
                </Stack>
              </BotaoLink>
            </Stack>
            <Contadores />
          </Stack>
          <ImagemEditavel caminho="area-publica/inicial" width="45%" display={{ xs: "none", sm: "flex" }} />
        </Stack>
      </Card>
    </Stack>
  );
}
