import { Box, Tab, Tabs } from "@mui/material";

import { Link, Outlet, useLocation } from "react-router";

export default function GestaoUsuariosLayout() {
  const { pathname } = useLocation();

  return (
    <>
      <Tabs
        value={pathname.endsWith("autorizacoes") ? "autorizacoes" : "usuarios"}
        indicatorColor="secondary"
        sx={{ "& .Mui-selected[role=tab]": { backgroundColor: "#232323" }, "mt": 2 }}
      >
        <Tab value="usuarios" label="Usuários" to="/gestor/admin/usuarios" component={Link} />
        <Tab value="autorizacoes" label="Autorizações" to="/gestor/admin/usuarios/autorizacoes" component={Link} />
      </Tabs>

      <Box
        sx={{
          display: "flex",

          flex: 1,
          border: "1px solid rgba(131,135,154, 0.19)",
          borderRadius: "0 0 18px 18px",
          p: 3,

          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}
