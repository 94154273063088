import { useCallback } from "react";

import { Stack, Typography } from "@mui/material";

import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import BotaoLink from "../../../../components/botao-link.tsx";
import { typedRequest } from "../../../../services/api";
import { useAuthAxios } from "../../../../services/fetchers.ts";
import Formulario from "../formulario/formulario.tsx";
import { Inputs } from "../tipos.ts";

export default function Novo() {
  const form = useForm<Inputs>({ defaultValues: { dados: [] } });
  const navigate = useNavigate();
  const authAxios = useAuthAxios();

  const submit = useCallback(
    async (values: Inputs) => {
      await typedRequest(authAxios, "post", "/cadastros/apis", undefined, {
        data: {
          cors: values.cors ?? "",
          nome: values.nome,
          recorte_temporal: values.recorteTemporal ?? "ultimo_mes",
          comentario: values.comentario,
          dados: values.dados,
        },
      });

      await navigate("/gestor/admin/apis");
    },
    [authAxios, navigate],
  );

  return (
    <FormProvider {...form}>
      <Stack gap={3}>
        <Stack width="7em">
          <BotaoLink variant="contained" to="/gestor/admin/apis" color="secondary">
            Voltar
          </BotaoLink>
        </Stack>
        <Stack border="1px solid rgba(131, 135, 154, 0.19)" borderRadius="18px" flex={1}>
          <Stack
            pb={3}
            sx={{
              "maxWidth": "40em",
              "alignSelf": "center",
              "color": "rgba(131, 135, 154, 1)",
              "& label": { fontSize: "14px" },
            }}
          >
            <Typography color="#000000" py={2}>
              Novo endpoint
            </Typography>
            <Formulario submit={submit} />
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
