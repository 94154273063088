import { AppBar, Box, CardMedia, Stack, Toolbar } from "@mui/material";

import { Link } from "react-router";

import logoMobNit from "../../assets/logo-mobnit.png";
import { useAuthContext } from "../../services/auth.ts";
import { useIsTabletOrSmaller } from "../../services/responsividade.ts";
import { DivisorVertical, Hoje, Ola } from "../shared";
import BackdropMobile from "../shared/backdrop-mobile";
import MenuMobile from "../shared/menu-mobile.tsx";
import MenuMobilidade from "../shared/menu-mobilidade.tsx";
import MenuPrefeitura from "../shared/menu-prefeitura.tsx";
import { StyledMenuButton } from "../shared/styled-popup-menu.tsx";

export default function PublicTopBar() {
  const { currentUser, isAdmin, isEditor, possuiPermissao, signOut } = useAuthContext();
  const isTablet = useIsTabletOrSmaller();

  return (
    <AppBar sx={{ bgcolor: "background.default", color: "secondary.main" }}>
      <Toolbar sx={{ gap: 2, py: { xs: 1.5, md: 0 }, pr: { xs: 0, md: 3.5 }, pl: { xs: 1.5, md: 3.5 } }}>
        {isTablet ? (
          <>
            <MenuMobile>
              <CardMedia component="img" height={40} image={logoMobNit} sx={{ objectFit: "contain" }} />
            </MenuMobile>
            <BackdropMobile />
          </>
        ) : (
          <>
            <Box mr="auto" component={Link} to="/">
              <CardMedia component="img" height={40} image={logoMobNit} sx={{ objectFit: "contain" }} />
            </Box>
            <Ola />

            <DivisorVertical />

            <Hoje />

            <DivisorVertical />

            <Stack direction="row">
              <StyledMenuButton to="/">Home</StyledMenuButton>

              <StyledMenuButton to="/sobre">Sobre</StyledMenuButton>

              <Box display="none">
                <MenuMobilidade />
              </Box>
              <StyledMenuButton to="/itinerarios">Itinerários</StyledMenuButton>
              <MenuPrefeitura />

              {!currentUser ? (
                <StyledMenuButton to="/login">Login</StyledMenuButton>
              ) : !isAdmin && !isEditor && !possuiPermissao("acesso") ? (
                <StyledMenuButton onClick={signOut}>Logout</StyledMenuButton>
              ) : (
                <StyledMenuButton to="/gestor">Área Gestor</StyledMenuButton>
              )}
            </Stack>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
